/* ======================================================================== */
/* 9. PJAX Transition Masonry Grid */
/* ======================================================================== */
var PJAXTransitionMasonryGrid = {
	name: 'masonryGrid',
	custom: ({
		current,
		next,
		trigger
	}) => {
		return $(trigger).data('pjax-link') == 'masonryGrid';
	},
	before: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				resolve(true);
			});

		});

	},
	beforeLeave: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateClonnedImage(data).then(function () {
				resolve(true)
			});

		});

	},
	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {
				resolve(true)
			});

		});

	},
	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateMasthead(data).then(function () {
				resolve(true);
			});

		});

	},
	after: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}
}
