/* ======================================================================== */
/* 41. SectionTextSlider */
/* ======================================================================== */
var SectionTextSlider = function ($scope) {

	var $target = $scope.find('.section-text-slider[data-os-animation]');

	if (!$target.length) {

		return;

	}

	var
		$slider = $target.find('.js-slider-text'),
		$backgrounds = $slider.find('.slider__background'),
		$helper = $target.find('.slider-text__helper'),
		$helperNormal = $target.find('.slider-text__helper-label-normal'),
		$helperView = $target.find('.slider-text__helper-label-view'),
		$helperIconLeft = $slider.find('.slider-text__helper-icon_left'),
		$helperIconRight = $slider.find('.slider-text__helper-icon_right');

	prepare().then(function () {
		animate();
	});

	function prepare() {

		return new Promise(function (resolve, reject) {

			var tl = new TimelineMax();

			tl
				.set($helper, {
					y: '20px',
					autoAlpha: 0
				})
				.set($target.find('.slider-text__line'), {
					transformOrigin: 'left center',
					scaleX: 0
				})
				.add(hideWords($target.find('h2'), '0', '0', '-30px', true))
				.add(hideWords($target.find('.slider-text__counter'), '0', '0', '-30px', true))
				.add(hideWordsVertical($helperNormal, '0', '0', '10px'))
				.add(hideWordsVertical($helperView, '0', '0', '10px'))
				.add(function () {
					new SliderText($slider);
				})
				.add(function () {
					resolve(true);
				});

		});

	}

	function animate() {

		var tl = new TimelineMax();

		tl
			.to($helper, 0.6, {
				autoAlpha: 1,
				y: '0px'
			})
			.add(animateWords($target.find('.slider-text__counter'), 1.2, 0.01, true), '0')
			.add(animateWords($helperNormal, 0.6, 0.01, true), '0')
			.add(animateWords($target.find('.slider-text__upper h2'), 1.2, 0.01), '0')
			.add(animateWords($target.find('.slider-text__lower h2'), 1.2, 0.01), '0')
			.staggerTo($target.find('.slider-text__line'), 1.2, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, 0.01, '0')
			.add(function () {
				hoverBackgrounds();
				new SliderBackgrounds($backgrounds);
			}, '0.6');


		createOSScene($target, tl);

	}

	function hoverBackgrounds() {

		var tl = new TimelineMax();

		$document
			.on('mouseenter touchstart', '.slider-text a[data-slide-id]', function () {

				tl
					.clear()
					.set($helper, {
						className: '+=color-white'
					})
					.to($helperIconLeft, 0.6, {
						x: '30px',
						rotation: 180,
						ease: Expo.easeInOut
					}, '0')
					.to($helperIconRight, 0.6, {
						x: '-30px',
						rotation: 180,
						ease: Expo.easeInOut
					}, '0')
					.add(hideWordsVertical($helperNormal, 0.6, 0.01, '30px', true), '0')
					.add(animateWords($helperView, 0.6, 0.01, true), '-=0.6');

			})
			.on('mouseleave touchend', '.slider-text a[data-slide-id]', function () {

				tl
					.clear()
					.set($helper, {
						className: '-=color-white'
					})
					.to($helperIconLeft, 0.6, {
						x: '0px',
						rotation: 0,
						ease: Expo.easeInOut
					}, '0')
					.to($helperIconRight, 0.6, {
						x: '0px',
						rotation: 0,
						ease: Expo.easeInOut
					}, '0')
					.add(hideWordsVertical($helperView, 0.6, 0.01, '-30px'), '0')
					.add(animateWords($helperNormal, 0.6, 0.01), '-=0.6');

			});

	}

}
