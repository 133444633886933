/* ======================================================================== */
/* 24. MenuOverlay */
/* ======================================================================== */
var MenuOverlay = function () {

	var $menu = $('.js-menu-overlay');

	if (!$menu.length) {
		return;
	}

	var
		$overlay = $('.header__wrapper-overlay-menu'),
		$widgets = $overlay.find('.figure-property'),
		$links = $menu.find('.menu-item-has-children > a'),
		$allLinks = $menu.find('a'),
		$submenus = $menu.find('.sub-menu'),
		$submenuButton = $('#js-submenu-back'),
		OPEN_CLASS = 'opened',
		SELECTED_CLASS = 'selected',
		HOVER_CLASS = 'menu-overlay_hover',
		tl = new TimelineMax();

	function openSubmenu($submenu, $currentMenu) {

		var
			$currentLinks = $currentMenu.find('> li > a .menu-overlay__item-wrapper'),
			$submenuLinks = $submenu.find('> li > a .menu-overlay__item-wrapper');

		tl
			.clear()
			.add([
				TweenMax.set($submenu, {
					autoAlpha: 1
				}),
				function () {

					$overlay.addClass('intransition lockhover');
					$submenus.removeClass(OPEN_CLASS);
					$submenu.not($menu).addClass(OPEN_CLASS);

					if (Modernizr.mq('(max-width: 991px)')) {
						if ($submenus.hasClass(OPEN_CLASS)) {
							hideLines($widgets, 0.6);
						} else {
							animateLines($widgets);
						}
					}

					if ($submenus.hasClass(OPEN_CLASS)) {
						tl.to($submenuButton, 0.3, {
							autoAlpha: 1,
							x: '0px'
						}, '-=1.2');
					} else {
						tl.to($submenuButton, 0.3, {
							autoAlpha: 0,
							x: '-10px'
						}, '-=1.2');
					}

				}
			], '0')
			.add(hideWords($currentLinks, 1.2, 0, '50px', true, '0.2'))
			.add(animateWords($submenuLinks, 1.2, 0.005), '-=0.6')
			.set($submenu, {
				zIndex: 100
			})
			.add(function () {
				$allLinks.removeClass(SELECTED_CLASS);
				$overlay.removeClass('intransition lockhover');
			}, '-=0.6');
	}

	function closeSubmenu($submenu, $currentMenu) {

		var
			$currentLinks = $currentMenu.find('> li > a .menu-overlay__item-wrapper'),
			$submenuLinks = $submenu.find('> li > a .menu-overlay__item-wrapper');

		tl
			.clear()
			.add([
				TweenMax.set($submenu, {
					zIndex: -1
				}),
				function () {

					$overlay.addClass('intransition lockhover');
					$submenus.removeClass(OPEN_CLASS);
					$currentMenu.not($menu).addClass(OPEN_CLASS);

					if (Modernizr.mq('(max-width: 991px)')) {
						if ($submenus.hasClass(OPEN_CLASS)) {
							hideLines($widgets, 0.6);
						} else {
							animateLines($widgets);
						}
					}

					if ($submenus.hasClass(OPEN_CLASS)) {
						TweenMax.to($submenuButton, 0.3, {
							autoAlpha: 1,
							x: '0px'
						});
					} else {
						TweenMax.to($submenuButton, 0.3, {
							autoAlpha: 0,
							x: '-10px'
						});
					}

				}
			])
			.add(hideWords($submenuLinks, 0.6, 0.005, '-50px'))
			.add(animateWords($currentLinks), '-=0.6')
			.add([
				TweenMax.set($submenu, {
					autoAlpha: 0
				}),
				function () {
					$overlay.removeClass('intransition lockhover');
				}
			], '-=0.6');
	}

	$links.on('click', function (e) {

		e.preventDefault();

		if (!$overlay.hasClass('intransition')) {

			var
				$el = $(this),
				$currentMenu = $el.parents('ul'),
				$submenu = $el.next('.sub-menu');

			$el.addClass(SELECTED_CLASS);
			openSubmenu($submenu, $currentMenu);
		}

	});

	$submenuButton.on('click', function (e) {

		e.preventDefault();

		if (!$overlay.hasClass('intransition')) {

			var $openedMenu = $submenus.filter('.' + OPEN_CLASS),
				$prevMenu = $openedMenu.parent('li').parent('ul');
			closeSubmenu($openedMenu, $prevMenu);

		}
	});

	$allLinks
		.on('mouseenter touchstart', function () {

			if ($submenus.filter('.opened').length) {
				$submenus.filter('.opened').addClass(HOVER_CLASS);
			} else {
				$menu.addClass(HOVER_CLASS);
			}

		})
		.on('mouseleave touchend', function () {

			$('.menu-overlay_hover').removeClass(HOVER_CLASS);

		});
}
