/* ======================================================================== */
/* 56. setLines */
/* ======================================================================== */
function setLines($target = $document, direction = 'vertical') {

	var
		$words = $target.find('.split-text .split-text__word'),
		$chars = $target.find('.split-chars .split-text__char');

	if (direction == 'vertical') {

		TweenMax.set($words, {
			y: '100%',
		});

		TweenMax.set($chars, {
			y: '100%',
		});

	} else {

		TweenMax.set($chars, {
			x: '50px',
			autoAlpha: 0
		});

	}

}
