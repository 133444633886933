/* ======================================================================== */
/* 18. figureProperty */
/* ======================================================================== */
// var FigureProperty = function ($target) {

// 	if (!$target.length) {
// 		return;
// 	}

// 	var $headline = $target.find('.figure-property__headline');

// 	prepare();
// 	animate();

// 	function prepare() {

// 		TweenMax.set($headline, {
// 			scaleX: 0,
// 			transformOrigin: 'left center'
// 		});

// 	}

// 	function animate() {

// 		var tl = new TimelineMax();

// 		tl
// 			.to($headline, 0.6, {
// 				scaleX: 1,
// 				ease: Expo.easeInOut
// 			})
// 			.add(animateLines($target), '0.4');

// 		createOSScene($target, tl);

// 	}

// }
