/* ======================================================================== */
/* 44. SliderHalfScreen */
/* ======================================================================== */
var SliderHalfScreen = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$heading = $slider.find('h2'),
		$description = $slider.find('p'),
		$link = $slider.find('.slider-halfscreen__wrapper-link'),
		tl = new TimelineMax(),
		$sliderImg = $slider.find('.js-slider-halfscreen__images'),
		$sliderContent = $slider.find('.js-slider-halfscreen__content'),
		overlapFactor = $sliderImg.data('overlap-factor') || 0;

	createSliders();
	hoverLinks();

	function createSliders() {

		var sliderImg = new Swiper($sliderImg, {
			autoplay: {
				enabled: $sliderImg.data('autoplay-enabled') || false,
				delay: $sliderImg.data('autoplay-delay') || 6000,
				disableOnInteraction: true
			},
			direction: 'vertical',
			preloadImages: true,
			lazy: {
				loadPrevNext: true,
				loadOnTransitionStart: true
			},
			speed: $sliderImg.data('speed') || 1200,
			simulateTouch: false,
			allowTouchMove: true,
			watchSlidesProgress: true,
			on: {
				progress: function () {
					var swiper = this;
					for (var i = 0; i < swiper.slides.length; i++) {

						var slideProgress = swiper.slides[i].progress,
							innerOffset = swiper.width * overlapFactor,
							innerTranslate = slideProgress * innerOffset;

						try {
							TweenMax.set(swiper.slides[i].querySelector('.slider-halfscreen__bg'), {
								y: innerTranslate + 'px',
								transition: swiper.params.speed + 'ms',
								rotationZ: 0.01,
								force3D: true
							});
						} catch (error) {

						}

					}
				},
				touchStart: function () {
					var swiper = this;
					for (var i = 0; i < swiper.slides.length; i++) {
						try {
							TweenMax.set(swiper.slides[i].querySelector('.slider-halfscreen__bg'), {
								transition: '',
								rotationZ: 0.01,
								force3D: true
							});
						} catch (error) {

						}

					}
				},
			}
		});

		var sliderContent = new Swiper($sliderContent, {
			simulateTouch: false,
			direction: 'vertical',
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			mousewheel: {
				eventsTarged: '.page-wrapper',
				releaseOnEdges: true,
			},
			keyboard: {
				enabled: true
			},
			navigation: {
				nextEl: '.js-slider-halfscreen__next',
				prevEl: '.js-slider-halfscreen__prev',
			},
			speed: $sliderImg.data('speed') || 1200,
			allowTouchMove: true,
			breakpoints: {
				992: {
					autoHeight: true
				}
			}
		});

		sliderContent.update();

		sliderContent.on('slideChange', () => {

			if (sliderContent.realIndex > sliderContent.previousIndex) {
				slideChangeTransition('next');
			}

			if (sliderContent.realIndex < sliderContent.previousIndex) {
				slideChangeTransition('prev');
			}

		});

		function slideChangeTransition(direction = 'next') {

			var
				$activeSlide = $(sliderContent.slides[sliderContent.realIndex]),
				$activeHeading = $activeSlide.find($heading),
				$activeLink = $activeSlide.find($link),
				$activeDescription = $activeSlide.find($description);

			tl.clear();

			$heading.each(function () {
				tl
					.add(hideWords($(this), 0.3, 0.02, direction === 'next' ? '50px' : '-50px', direction === 'next' ? true : false), '0')
					.add(hideWords($heading, '0', '0', direction === 'next' ? '-50px' : '50px'));
			});

			$description.each(function () {
				tl.add(hideLines($(this), 0.6, 0.01, '100%', true), '0');
			});

			$link.each(function () {
				tl.to($(this), 0.6, {
					y: '15px',
					autoAlpha: 0
				}, '0');
			});

			tl
				.add(animateWords($activeHeading, 1.2, 0.02, direction === 'next' ? true : false))
				.add(animateLines($activeDescription, 1.2, 0.01, direction === 'next' ? true : false), '-=1.2')
				.to($activeLink, 0.6, {
					y: '0px',
					autoAlpha: 1
				}, '-=1.2');

		}

		sliderImg.controller.control = sliderContent;
		sliderContent.controller.control = sliderImg;

	}

	function hoverLinks() {

		$document
			.on('mouseenter touchstart', '.slider-halfscreen__link', function () {

				var $targetBackground = $sliderImg.find('.swiper-slide-active .slider-halfscreen__images-slide-inner'),
					$linkLine = $sliderContent.find('.swiper-slide-active .slider-halfscreen__link-line');

				if (!$targetBackground.length) {
					return;
				}

				TweenMax.to($targetBackground, 0.6, {
					scale: 1.05,
					ease: Expo.easeInOut
				});

				TweenMax.to($linkLine, 0.6, {
					width: '70px',
					ease: Expo.easeInOut
				});

			})
			.on('mouseleave touchend', '.slider-halfscreen__link', function () {

				var $targetBackground = $sliderImg.find('.swiper-slide-active .slider-halfscreen__images-slide-inner'),
					$linkLine = $sliderContent.find('.swiper-slide-active .slider-halfscreen__link-line');

				if (!$targetBackground.length) {
					return;
				}

				TweenMax.to($targetBackground, 0.6, {
					scale: 1,
					ease: Expo.easeInOut
				});

				TweenMax.to($linkLine, 0.6, {
					width: '60px',
					ease: Expo.easeInOut
				});

			});

	}

}
