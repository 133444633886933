/* ======================================================================== */
/* 49. social */
/* ======================================================================== */
var Social = function () {

	var $elements = $('.social__item a');

	if (!$elements.length) {
		return;
	}

	var circle = new Circle();

	$elements.each(function () {
		circle.animate($(this));
	});

}
