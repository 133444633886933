/* ======================================================================== */
/* 21. Header */
/* ======================================================================== */
var Header = function () {

	var $overlay = $('.header__wrapper-overlay-menu');

	if (!$overlay.length) {
		return;
	}

	var
		tl = new TimelineMax(),
		$menuLinks = $overlay.find('.menu-overlay > li > a'),
		$allLinks = $overlay.find('a'),
		$submenu = $overlay.find('.menu-overlay .sub-menu'),
		$submenuButton = $('#js-submenu-back'),
		$submenuLinks = $submenu.find('> li > a'),
		$overlayWidgets = $overlay.find('.header__wrapper-overlay-widgets'),
		$widgets = $overlay.find('.figure-property'),
		$headerLeft = $('.header__col-left'),
		OPEN_CLASS = 'header__burger_opened';

	clickBurger();
	prepare();
	setOverlayMenu();

	function prepare() {

		TweenMax.set($menuLinks.find('.split-chars__char'), {
			x: '-100px',
			autoAlpha: 0
		});

		TweenMax.set($submenuLinks.find('.split-chars__char'), {
			x: '-30px',
			autoAlpha: 0
		});

	}

	function setOverlayMenu() {

		getScrollTop();
		window.$overlay.removeClass('intransition lockhover opened');
		$submenu.removeClass('opened');
		$allLinks.removeClass('selected');

		TweenMax.set(window.$overlay, {
			scaleX: 0,
			autoAlpha: 0,
		});

		TweenMax.set([$submenu, $submenuButton], {
			autoAlpha: 0
		});

		TweenMax.set($overlayWidgets, {
			scaleY: 0,
			transformOrigin: 'bottom center',
		});

		TweenMax.set(window.$wrapperBackgrounds, {
			clearProps: 'width,height,left,right,top,bottom,backgroundColor',
		});

		setLines($widgets);

	}

	function closeOverlayMenu(hideBackgrounds) {

		var
			$submenuLinksCurrent = $submenu.filter('.opened').find($submenuLinks),
			$pageWrapper = $('.page-wrapper'),
			$layers = [];

		$layers.push(window.$overlay);

		if (hideBackgrounds == true) {
			$layers.push(window.$wrapperBackgrounds);
		}

		tl.timeScale(1.5);

		return tl
			.clear()
			.add([
				TweenMax.set(window.$overlay, {
					className: '+=intransition',
					transformOrigin: 'right center',
					zIndex: 500
				}),
				TweenMax.set(window.$wrapperBackgrounds, {
					transformOrigin: 'right center',
					zIndex: 100,
				}),
				TweenMax.set($burger, {
					className: '-=header__burger_opened'
				}),
				TweenMax.set($pageWrapper, {
					clearProps: 'overflow,height',
					x: '-5vw',
					force3D: true,
					transformOrigin: 'right center',
				}),
				function () {
					restoreScrollTop();
				}
			])
			.add([
				hideWords($menuLinks, 1.2, 0.01, '100px', true, 'start'),
				hideWords($submenuLinksCurrent, 1.2, 0.01, '30px', true, 'start'),
				hideLines($widgets, 0.6),
			], '0.3')
			.to($overlayWidgets, 0.6, {
				scaleY: 0,
				transformOrigin: 'top center',
				ease: Expo.easeInOut
			}, '1')
			.to($layers, 1.2, {
				scaleX: 0,
				ease: Expo.easeInOut
			}, '1')
			.fromTo($pageWrapper, 2.4, {
				autoAlpha: 1,
			}, {
				x: '0vw',
				force3D: true,
				autoAlpha: 1,
				ease: Expo.easeInOut,
			}, '0.3')
			.to($submenuButton, 0.6, {
				x: '-10px',
				autoAlpha: 0
			}, '0.3')
			.fromTo($headerLeft, 2.4, {
				x: '-50px',
			}, {
				x: '0px',
				autoAlpha: 1,
				ease: Expo.easeInOut
			}, '0.3')
			.add(function () {
				setOverlayMenu();
				prepare();
			});
	};

	function openOverlayMenu() {

		var $pageWrapper = $('.page-wrapper');

		tl.timeScale(1);

		tl
			.clear()
			.add(function () {
				getScrollTop();
				window.$overlay.addClass('intransition opened');
			})
			.set(window.$overlay, {
				autoAlpha: 1,
				transformOrigin: 'left center',
				zIndex: 500
			})
			.set(window.$wrapperBackgrounds, {
				scaleX: 0,
				transformOrigin: 'left center',
				autoAlpha: 1,
				zIndex: 100,
			})
			.set(window.$backgroundsOverlay, {
				autoAlpha: 0.6
			})
			.to($pageWrapper, 1.2, {
				x: '10vw',
				force3D: true,
				transformOrigin: 'left center',
				ease: Expo.easeInOut,
				onComplete: function () {
					TweenMax.set($pageWrapper, {
						autoAlpha: 0
					});
				}
			})
			.to($headerLeft, 1.2, {
				x: '50px',
				autoAlpha: 0,
				ease: Expo.easeInOut
			}, '0')
			.to([window.$overlay, window.$wrapperBackgrounds], 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, '0.2')
			.add(animateWords($menuLinks, 1.8, 0.01, true, false, '-=1.5'), '0.6')
			.to($overlayWidgets, 1.2, {
				scaleY: 1,
				ease: Expo.easeInOut,
			}, '0.4')
			.add(animateLines($widgets), '1.0')
			.add(function () {
				window.$overlay.removeClass('intransition');
			}, '0.9');
	};

	function clickBurger() {

		window.$burger.off().on('click', function (e) {

			e.preventDefault();

			if (!$overlay.hasClass('intransition')) {

				if (window.$burger.hasClass(OPEN_CLASS)) {
					closeOverlayMenu(true);
					window.$burger.removeClass(OPEN_CLASS);
				} else {
					openOverlayMenu();
					window.$burger.addClass(OPEN_CLASS);
				}

			}

		});

	}

	this.hideOverlayMenu = function (hideBackgrounds) {

		return closeOverlayMenu(hideBackgrounds);

	}

}
