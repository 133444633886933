/* ======================================================================== */
/* 28. SmoothScroll */
/* ======================================================================== */
var SmoothScroll = function () {

	var $smoothScroll = $('#js-smooth-scroll');

	// don't launch on mobiles
	if (!$smoothScroll.length || Modernizr.touchevents) {
		return;
	}

	$smoothScroll.addClass('smooth-scroll');

	if (window.theme.smoothScroll.plugins.edgeEasing) {
		Scrollbar.use(window.EdgeEasingPlugin);
	}

	window.SB = Scrollbar.init($smoothScroll[0], window.theme.smoothScroll);

	// handle smooth anchor scrolling
	$('a[href^=\\#]:not([href=\\#])').each(function () {

		var
			$current = $(this),
			$el = $($current.attr('href'));

		if ($el.length) {
			$current.on('click', function () {
				window.SB.scrollIntoView($el.get(0));
			});
		}

	});


}
