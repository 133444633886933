/* ======================================================================== */
/* 2. PJAX Animate Masthead */
/* ======================================================================== */
function PJAXAnimateMasthead(data) {

	return new Promise(function (resolve, reject) {

		var
			$nextContainer = $(data.next.container),
			$nextMasthead = $nextContainer.find('.section-masthead').first(),
			$nextBgWrapper = $nextMasthead.find('.section-masthead__background'),
			tl = new TimelineMax(),
			$selectedBackground = window.$backgrounds.filter('.selected');

		if (!$selectedBackground.length || !$nextBgWrapper.length) {
			resolve(true);
		}

		var
			$nextBg = $nextBgWrapper.find('.art-parallax__bg'),
			$nextOverlay = $nextMasthead.find('.section-masthead__overlay'),
			nextBgWrapperPosition = $nextBgWrapper.get(0).getBoundingClientRect(),
			transformMatrix = $nextBg.css('transform');

		tl.timeScale(1.25);
		scrollToVeryTop();

		if ($nextOverlay.length) {

			tl.to(window.$backgroundsOverlay, 0.6, {
				autoAlpha: 0.6,
				ease: Expo.easeInOut
			}, '0');

		} else {

			tl.set(window.$backgroundsOverlay, {
				autoAlpha: 0
			}, '0');

		}

		tl.add([
				TweenMax.to(window.$wrapperBackgrounds, 1.2, {
					top: nextBgWrapperPosition.top,
					left: nextBgWrapperPosition.left,
					width: nextBgWrapperPosition.width,
					height: nextBgWrapperPosition.height,
					ease: Expo.easeInOut,
				}),
				TweenMax.to($selectedBackground, 1.2, {
					transition: 'none',
					transform: transformMatrix,
					ease: Expo.easeInOut,
				})
			], '0')
			.set(window.$wrapperBackgrounds, {
				autoAlpha: 0
			})
			.add(function () {
				resolve(true);
			});

	});

}
