/* ======================================================================== */
/* 38. SectionIntro */
/* ======================================================================== */
var SectionIntro = function ($scope) {

	var $target = $scope.find('.section-intro[data-os-animation]');

	if (!$target.length) {

		return;

	}

	var
		$headline = $target.find('.section-intro__headline'),
		$heading = $target.find('h1');

	prepare();
	animate();

	function prepare() {

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center'
		});

	}

	function animate() {

		var tl = new TimelineMax();

		tl
			.add(animateLines($heading, 1.2, 0.01))
			.to($headline, 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, '0.3')

		createOSScene($target, tl);

	}

}
