/* ======================================================================== */
/* 17. FigurePortfolio */
/* ======================================================================== */
var FigurePortfolio = function ($scope) {

	var $target = $scope.find('.figure-portfolio');

	$target.each(function () {

		var
			$current = $(this),
			$img = $current.find('img'),
			transformMatrix = $img.css('transform');

		$current
			.on('mouseenter', function () {
				TweenMax.to($img, 0.6, {
					transform: 'none',
					ease: Expo.easeInOut
				})
			})
			.on('mouseleave', function () {
				TweenMax.to($img, 0.6, {
					transform: transformMatrix,
					ease: Expo.easeInOut
				})
			});

	});

}
