window.$document = $(document);
window.$pageWrapper = $('.page-wrapper');
window.$pageHeader = $('.header__wrapper-overlay-menu');
window.$barbaWrapper = $('[data-barba="wrapper"]');
window.$body = $('body');
window.$wrapperBackgrounds = $('.project-backgrounds');
window.$backgroundsOverlay = $('.project-backgrounds__overlay');
window.$overlay = $('.header__wrapper-overlay-menu');
window.$backgrounds = $('.project-backgrounds__background');
window.$backgroundLinks = $('a[data-post-id]');
window.$burger = $('#js-burger');
window.$header = $('.header');
window.$preloader = $('.preloader');
window.$curtains = $('.preloader__curtain');
window.$spinner = $('.js-spinner');

/**
 * Try to use high performance GPU on dual-GPU systems
 */
runOnHighPerformanceGPU();

/**
 * Default Theme Options
 * Used to prevent errors if there is
 * no data provided from backend
 */

if (typeof window.theme === 'undefined') {
	window.theme = {
		typography: {
			fontPrimary: 'Poppins',
			fontSecondary: 'Oswald'
		},
		smoothScroll: {
			damping: 0.08,
			renderByPixels: true,
			continuousScrolling: false,
			plugins: {
				edgeEasing: true
			}
		}
	}
}

/**
 * ScrollMagic Setup
 */
window.SMController = new ScrollMagic.Controller();
window.SMController.enabled(false);
window.SMSceneTriggerHook = 0.8;
window.SMSceneReverse = false;

/**
 * Theme Fonts
 */
window.fontPrimaryObserver = new FontFaceObserver(window.theme.typography.fontPrimary);
window.fontSecondaryObserver = new FontFaceObserver(window.theme.typography.fontSecondary);

/**
 * Don't save scroll position
 * after AJAX transition
 */
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}

$document.ready(function () {

	Preloader().then(function () {

		/**
		 * Split text only after ensuring
		 * that fonts are rendered
		 */
		Promise
			.all([window.fontPrimaryObserver.load(), window.fontSecondaryObserver.load()])
			.then(doSplitText($document))
			.then(setLines($document))
			.then(function () {
				window.SMController.enabled(true);
				window.SMController.update(true);
				initComponents($document);
			});

	});

	new PJAX();
	new ProjectBackgrounds();
	window.InteractiveCursor = new Cursor();

});

function initComponents($scope = $document) {

	new SmoothScroll();

	window.PageHeader = new Header();
	if (typeof window.PageMenu === 'undefined') {
		window.PageMenu = new MenuOverlay();
	}

	lazyLoad($scope);
	new Parallax($scope);
	new Grid();
	new Form();
	new SliderImages($scope);
	new SliderTestimonials($scope);
	new AsideCounters($scope);
	new GMap($scope);
	new SectionMasthead($scope);
	new SectionContent($scope);
	new SectionIntro($scope);
	new SectionTextSlider($scope);
	new SectionHeadingsSlider($scope);
	new SectionHalfScreenSlider($scope)
	new SectionNavProjects($scope)
	new SectionComposition($scope);
	new FigurePortfolio($scope);
	$('.js-video').magnificPopup();
	fixMobileBarHeight();

}
