/* ======================================================================== */
/* 45. SliderHeadings */
/* ======================================================================== */
var SliderHeadings = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$heading = $slider.find('h2'),
		$description = $slider.find('p'),
		tl = new TimelineMax(),
		slider = new Swiper($slider, {
			simulateTouch: false,
			allowTouchMove: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			speed: 1200,
			centeredSlides: true,
			mousewheel: {
				eventsTarged: '.page-wrapper',
				releaseOnEdges: true,
			},
			keyboard: {
				enabled: true
			},
			pagination: {
				el: '.js-slider-headings__dots',
				type: 'bullets',
				bulletElement: 'div',
				clickable: true,
				bulletClass: 'slider__dot',
				bulletActiveClass: 'slider__dot_active'
			},
			navigation: {
				nextEl: '.js-slider-headings__next',
				prevEl: '.js-slider-headings__prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					centeredSlides: true,
				}
			}
		});

		slider.on('slideChange', () => {

			if (slider.realIndex > slider.previousIndex) {
				slideChangeTransition('next');
			}
	
			if (slider.realIndex < slider.previousIndex) {
				slideChangeTransition('prev');
			}
	
		});
	
		function slideChangeTransition(direction = 'next') {
	
			var
				$activeSlide = $(slider.slides[slider.realIndex]),
				$activeDescription = $activeSlide.find($description),
				$activeHeading = $activeSlide.find($heading);
	
			tl.clear();
	
			$heading.each(function () {
				tl
					.add(hideWords($(this), 0.3, 0.02, direction === 'next' ? '50px' : '-50px', direction === 'next' ? true : false), '0')
					.add(hideWords($heading, '0', '0', direction === 'next' ? '-50px' : '50px'))
			});
	
			$description.each(function () {
				tl.add(hideLines($(this), 0.6, 0.01, '100%', true), '0');
			});
	
			tl
				.add(animateWords($activeHeading, 1.2, 0.02, direction === 'next' ? true : false))
				.add(animateLines($activeDescription, 1.2, 0.01, direction === 'next' ? true : false), '-=1.2');
	
		}

	renderSliderCounter(
		slider,
		$slider.find('.js-slider-headings-counter-current'),
		'slider-headings__counter-number',
		''
	);

}
