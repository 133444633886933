/* ======================================================================== */
/* 10. PJAX Transition Nav Projects */
/* ======================================================================== */
var PJAXTransitionNavProjects = {
	name: 'navProjects',
	custom: ({
		current,
		next,
		trigger
	}) => {
		return $(trigger).data('pjax-link') == 'navProjects';
	},
	before: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				resolve(true);
			});

		});

	},
	beforeLeave: (data) => {

		return new Promise(function (resolve, reject) {

			var
				tl = new TimelineMax(),
				$customPositionElement = $('.section-nav-projects__backgrounds'),
				$inner = $('.section-nav-projects__inner'),
				$backgroundOverlay = $('.section-nav-projects__overlay');

			tl
				.to($inner, 0.3, {
					transition: 'none',
					autoAlpha: 0,
					ease: Expo.easeInOut,
				}, '0')
				.to($backgroundOverlay, 0.3, {
					transition: 'none',
					autoAlpha: 0,
					ease: Expo.easeInOut,
				}, '0')
				.add(function () {
					PJAXAnimateClonnedImage(data, $customPositionElement).then(function () {
						resolve(true);
					});
				});

		});

	},
	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {
				resolve(true)
			});

		});

	},
	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXAnimateMasthead(data).then(function () {
				resolve(true);
			});

		});

	},
	after: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}
}
